import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-custom-badge-renderer',
  templateUrl: './custom-badge-renderer.component.html',
  styleUrl: './custom-badge-renderer.component.scss'
})
export class CustomBadgeRendererComponent implements ICellRendererAngularComp {

  params: any;
  value: string = '';
  badgeClass: string = '';

  agInit(params: any): void {

    this.params = params;
    this.value = params.value;

    switch (this.value) {
      case 'Booked': //booked
        this.badgeClass = 'ag-grid-pill-info';
        break;
      case 'Delivered': //customer_delivered
        this.badgeClass = 'ag-grid-pill-warning';
        break;
      case 'Provider Delivered': //provider_delivered
        this.badgeClass = 'ag-grid-pill-success';
        break;
      case 'In Transit': //provider_collected
        this.badgeClass = 'ag-grid-pill-success';
        break;
      default:
        this.badgeClass = 'ag-grid-pill-secondary';
        break;
    }
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    this.params = params;
    this.value = params.value;
    return true;
  }
}
