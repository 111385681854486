import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-date-status',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './date-status.component.html',
  styleUrl: './date-status.component.scss'
})

export class DateStatusComponent implements ICellRendererAngularComp {

  public dateValue = '';
  public showExclamation = false;

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.refresh(params);
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    this.dateValue = params.value;
    if (!this.dateValue) {
      this.showExclamation = false;
      return true;
    }

    const dateParts = this.dateValue.split(' - ');

    // Check if it's a single date
    if (dateParts.length === 1) {
      const singleDate = new Date(dateParts[0]);

      // If the date is invalid or not in the future, return the value without exclamation
      if (isNaN(singleDate.getTime()) || singleDate < new Date()) {
        this.showExclamation = false;
        return true;
      }

      // Check if it falls within the 3-day window for warning
      const dueDate = new Date(singleDate);
      dueDate.setDate(singleDate.getDate() - 3);
      this.showExclamation = new Date() >= dueDate;
      return true;
    }


    const [startDateStr, endDateStr] = dateParts;

    const currentDate = new Date();
    const endDate = new Date(endDateStr);

    const dueDate = new Date(endDate);
    dueDate.setDate(endDate.getDate() - 3);

    this.showExclamation = currentDate >= dueDate && currentDate >= endDate;


    return true
  }

}
