import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-provider-renderer',
  templateUrl: './provider-renderer.component.html',
  styleUrl: './provider-renderer.component.scss'
})
export class ProviderRendererComponent {

  params: any;
  providerProfileUrl: string = '';
  providerProfileImageUrl: string = '';
  providerUsername: string = '';

  assetEndPoint = 'https://truckit-profile-files.s3-ap-southeast-2.amazonaws.com';

  agInit(params: any): void {
    this.refresh(params);
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    this.params = params;
    const providerName = params.data.provider_business_name;
    this.providerProfileUrl = `${environment.appUrl}/provider/profile/${providerName}`
    // this.providerProfileImageUrl = `${environment.appUrl}/${params.data.provider_profile}`
    this.providerProfileImageUrl = `${this.assetEndPoint}/${params.data.profile_picture}`
    this.providerUsername = providerName
    return true;
  }

}
