import { Component, TemplateRef, ViewChild, inject } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {StorageService} from "../../../../services/storage/storage.service";


@Component({
  selector: 'app-attachment-bookings',
  templateUrl: './attachment-bookings.component.html',
  styleUrls: ['./attachment-bookings.component.scss']
})
export class AttachmentBookingsComponent implements ICellRendererAngularComp {

  public params: any;
  public value: any;
  public isAdjustmentInvoice: boolean = false;

  public titleModal: any;

  private modalService = inject(NgbModal);
  public pdfSrc: any;


  constructor(private http: HttpClient) { }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.value = params.value
    return true;
  }


  agInit(params: ICellRendererParams & {isAdjustmentInvoice: boolean}): void {
    this.params = params;
    this.value = params.value
    this.isAdjustmentInvoice = params.isAdjustmentInvoice;
    console.log(params.isAdjustmentInvoice);
  }

  private downloadFile(url: string): any {
    if(this.isAdjustmentInvoice){
      const headers = new HttpHeaders({
        'Authorization': StorageService.getCookie('AUTH')
      })
      return this.http.get(url, { headers: headers, responseType: 'blob' })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
    }
    return this.http.get(url, { responseType: 'blob' })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  openAttachment(content: TemplateRef<any>, params: any) {
    this.titleModal = params.data.booking_id

    let attachmentLink  = '';

    if(this.isAdjustmentInvoice){
      attachmentLink = `${environment.domain}/v1/adjustment/invoice/${params.data.adjustment_invoice_id}`;
      // attachmentLink = `${environment.appUrl}/api/frontend/listing/upload-proof/doc-attachments?booking_id=${params.data.booking_id}&proof_type=booking`;
    }else{
      attachmentLink = `${environment.appUrl}/api/frontend/listing/upload-proof/doc-attachments?booking_id=${params.data.booking_id}&proof_type=delivery`;
    }


    this.downloadFile(attachmentLink).subscribe((res: any) => {
      this.pdfSrc = res

      this.modalService.open(content, { fullscreen: true })
    });


  }

}
